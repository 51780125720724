import { useTrack } from '../../../../shared/hooks/context/Track.Context';
import { HubTypeEnum } from '../../../../shared/interfaces/enums/HubType.Enum';
import { ServiceNameEnum } from '../../../../shared/interfaces/enums/ServiceName.Enum';
import NewsList from '../../../../shared/services/sub/news/NewsList';
import MetaDescription from '../../../../shared/utils/seo/MetaDescription';
import Menu from '../../components/Menu';

export default function NewsSection() {
  const { track, loading, error } = useTrack();

  if (!track) {
    return <>Loading...</>;
  }

  return (
    <>
      <MetaDescription name={'News'} description={'This is a news list section'} />
      <h1>{track.name}</h1>
      <Menu />
      <h2>News list</h2>
      <NewsList />
    </>
  );
}
