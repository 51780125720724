import { useEffect, useState } from 'react';
import { PaginatedListDTO } from '../../shared/interfaces/common/PaginatedList.DTO';
import { IntegrationInterfaceDTO } from '../../shared/interfaces/IntegrationBridge/IntegrationInterface.DTO';
import api from '../../shared/services/api/api';
import { ServiceNameEnum } from '../../shared/interfaces/enums/ServiceName.Enum';
import { EnumType } from 'typescript';
import SingleNews from '../../shared/services/sub/news/SingleNews';
import SingleEvent from '../../shared/services/sub/events/SingleEvent';
import ContentLayoutContainer from '../../shared/components/ui/containers/ContentLayout.Container';
import TrackSingleToList from '../../shared/services/main/tracks/Track.Single.ToList';
import BlockTemp from '../../shared/components/Block.Temp';

export default function FeedAllHubs() {
  const [intInterfaces, setIntInterfaces] = useState<PaginatedListDTO<IntegrationInterfaceDTO | null>>();
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get(`/IntBridgeRequest/GetIIListByAllHubsType?pageIndex=1&pageSize=100`);
        if (response.data && response.data.items) {
          setIntInterfaces(response.data);
        } else {
          throw new Error('Invalid API response');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  if (!intInterfaces || intInterfaces.items.length === 0) {
    return <>No data available</>;
  }

  // 3️⃣ Компонент для выбора рендера
  const SwitchCaseComponent: React.FC<{ serviceName: ServiceNameEnum; id: string }> = ({ serviceName, id }) => {
    switch (serviceName) {
      case ServiceNameEnum.Club:
        return <>Club, not implemented</>;
      case ServiceNameEnum.Event:
        return <SingleEvent id={id} serviceInfo={true} />;
      case ServiceNameEnum.News:
        return <SingleNews id={id} serviceInfo={true} />;
      case ServiceNameEnum.School:
        return <>School, not implemented</>;
      case ServiceNameEnum.Track:
        return <TrackSingleToList trackId={id} />;
      default:
        return <div className="card bg-light p-2">❓ Неизвестный сервис</div>;
    }
  };

  return (
    <>
      {' '}
      <div className="row">
        <div className="col-lg-9">
          {intInterfaces.items.map((intInterface) => (
            <div key={intInterface?.id} className="pt-2">
              <SwitchCaseComponent serviceName={intInterface?.serviceName || 0} id={intInterface?.id || ''} />
            </div>
          ))}
          <br />
        </div>
        <div className="col-lg-3">
          <BlockTemp />
        </div>
      </div>
    </>
  );
}
