import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet-async';
import AboutSection from '../sections/track-page-section/About.Section';
import Menu from '../components/Menu';
import ContentLayoutContainer from '../../../shared/components/ui/containers/ContentLayout.Container';

export default function TrackPage() {
  return (
    <>
      <ContentLayoutContainer>
        <Outlet />
      </ContentLayoutContainer>
    </>
  );
}
