import { NavLink } from 'react-router-dom';
import { HubTypeEnum } from '../../../interfaces/enums/HubType.Enum';
import { getHubUrlPrefix } from '../../../utils/services/GetHubUrlPrefix';

export default function TrackMenu({ url, hub }: { url: string; hub: HubTypeEnum }) {
  const hubPrefix = getHubUrlPrefix(hub);
  const trackUrl = `${hubPrefix}/tracks/view/${url}`;

  return (
    <>
      <>
        <hr />
        <NavLink to={`${trackUrl}`}>Home</NavLink> - <NavLink to={`${trackUrl}/news`}>News</NavLink> - <NavLink to={`${trackUrl}/events`}>Events</NavLink>
      </>
    </>
  );
}
