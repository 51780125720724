import { useTrack } from '../../../../shared/hooks/context/Track.Context';
import EventList from '../../../../shared/services/sub/events/EventList';
import MetaDescription from '../../../../shared/utils/seo/MetaDescription';
import Menu from '../../components/Menu';

export default function EventsSection() {
  const { track, loading, error } = useTrack();

  if (!track) {
    return <>Loading...</>;
  }

  return (
    <>
      <MetaDescription name={'News'} description={'This is a event list section'} />
      <h1>{track.name}</h1>
      <Menu />
      <h2>Event list</h2>
      <EventList serviceUrl={track.url} />
    </>
  );
}
