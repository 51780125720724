import BlockTemp from '../../../../shared/components/Block.Temp';
import ContentLayoutContainer from '../../../../shared/components/ui/containers/ContentLayout.Container';
import { HubTypeEnum } from '../../../../shared/interfaces/enums/HubType.Enum';
import { ServiceNameEnum } from '../../../../shared/interfaces/enums/ServiceName.Enum';
import NewsListByHubAndService from '../../../../shared/services/sub/news/NewsList.ByHubAndService';
import MetaDescription from '../../../../shared/utils/seo/MetaDescription';
import MotorsportCatNav from '../../components/navs/Motorsport.Cat.Nav';

export default function NewsListSection() {
  return (
    <>
      <MetaDescription name="Motorsport Hub - News" description="This section includes all categories related to motorsport news coverage." />
      <div className="container-fluid">
        <h2
          className=" pt-2"
          style={{
            color: 'rgba(224, 224, 224, 0.7)',
          }}
        >
          News
        </h2>
        <hr className="text-white" />
        <div className="row">
          <div className="col-lg-9">
            <NewsListByHubAndService news={{ hubName: HubTypeEnum.Motorsport, serviceName: ServiceNameEnum.News }} />
          </div>
          <div className="col-lg-3">
            <BlockTemp />
          </div>
        </div>
      </div>
    </>
  );
}
