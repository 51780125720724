import ContentLayoutContainer from '../../../shared/components/ui/containers/ContentLayout.Container';
import MetaDescription from '../../../shared/utils/seo/MetaDescription';

export default function MotorsportHomeSection() {
  return (
    <>
      <MetaDescription name="Motorsport Hub" description="Passionate about racing? Discover the latest from the world of motorsport — right here." />
      <div className="container-fluid">
        <ContentLayoutContainer>
          Welcome to motorsport hub!
          <br /> Section is under construction..
        </ContentLayoutContainer>
      </div>
    </>
  );
}
