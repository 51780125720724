import { Outlet, Route, Routes } from 'react-router-dom';
import FormulaRacingLayout from '../../components/layouts/formula-racing-layouts/formula-racing-layout/FormulaRacing.Layout';
import FormulaRacingCategory from '../../categories/formula-racing/FormulaRacing.Category';
import RallyLayout from '../../components/layouts/rally-layouts/rally-layout/Rally.Layout';

export default function RallyNonAuthRoutes() {
  return (
    <>
      {/* prettier-ignore */}
      <Routes>
        <Route path="" element={<Outlet />}>

            <Route path="" element={<RallyLayout />}>
              <Route path="" element={<FormulaRacingCategory/>}></Route>
            </Route>



          
        </Route>
      </Routes>
    </>
  );
}
