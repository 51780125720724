import { Route, Routes } from 'react-router-dom';
import Layout from '../../../shared/components/layout/Layout';
import MotorbikesHomeSection from '../sections/Motorbikes.Home.Section';
import MotorbikesLayout from '../components/layouts/motorbikes-layout/Motorbikes.Layout';

export default function MotorbikesNonAuthRoutes() {
  return (
    <Routes>
      <Route path="/" element={<MotorbikesLayout />}>
        <Route path="" element={<MotorbikesHomeSection />}></Route>
      </Route>
    </Routes>
  );
}
