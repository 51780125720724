import { Outlet } from 'react-router-dom';
import HubNav from '../components/navs/Hub.Nav';
import ContentLayoutContainer from '../../../shared/components/ui/containers/ContentLayout.Container';
import Layout from '../../../shared/components/layout/Layout';
import MotorsportCategoriesNav from '../components/navs/Motorsport.Categories.Nav';
import MotorsportTestHeader from '../components/layouts/motorsport-layouts/Motorsport.Test.Header';
import MotorsportHeader from '../components/layouts/motorsport-layouts/Motorsport.Header';
import MetaDescription from '../../../shared/utils/seo/MetaDescription';

export default function MotorsportPage() {
  return (
    <>
      <div className="pt-1">
        {/* <MotorsportHeader /> */}
        <div className="container-fluid py-2">
          <HubNav />
        </div>
      </div>
      <div className="container-fluid pt-1 p-0">
        <Outlet />
      </div>
    </>
  );
}
