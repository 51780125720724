import { Outlet } from 'react-router-dom';
import Header from '../../../../../../shared/components/layout/header/Header';
import Footer from '../../../../../../shared/components/layout/Footer';
import FormulaRacingHeader from './header/FormulaRacing.Header';

export default function FormulaRacingLayout() {
  return (
    <>
      <FormulaRacingHeader />

      <Outlet />

      <Footer />
    </>
  );
}
