import { useEffect, useState } from 'react';
import { TrackDTO } from '../../../interfaces/main/tracks/Track.DTO';
import api from '../../api/api';
import { Link } from 'react-router-dom';
import { getHubUrlPrefix } from '../../../utils/services/GetHubUrlPrefix';
import { HubTypeEnum } from '../../../interfaces/enums/HubType.Enum';

export default function TrackSingleToList({ trackId }: { trackId: string }) {
  const [track, setTrack] = useState<TrackDTO | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get(`/TracksRequest/GetTrackById?id=${trackId}`);
        if (response.data) {
          setTrack(response.data);
        } else {
          throw new Error('Invalid API response');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [trackId]);

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  if (!track) {
    return <>No data available</>;
  }

  const hubPrefix = getHubUrlPrefix(track.assignedHub);
  const trackUrl = `${hubPrefix}/tracks/view/${track.url}`;

  return (
    <>
      <Link to={trackUrl} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
        <div
          className="card mb-2"
          style={{
            flex: '1 1 calc(30% - 20px)',
            // minWidth: '250px',
            // maxWidth: '350px',
            border: '1px solid #fff',
            borderRadius: '8px',
            borderColor: 'black',
            boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            //flexDirection: 'column',
            //overflow: 'hidden',
            //transition: 'transform 0.3s ease', // Анимация увеличения
          }}
          // onMouseEnter={(e) => {
          //   (e.currentTarget as HTMLDivElement).style.transform = 'scale(1.05)';
          // }}
          // onMouseLeave={(e) => {
          //   (e.currentTarget as HTMLDivElement).style.transform = 'scale(1)';
          // }}
        >
          <div className="card-body" style={{ flex: '1', padding: '10px' }}>
            <p className="card-text" style={{ color: '#333' }}>
              {track?.name}
            </p>
          </div>
        </div>
      </Link>
    </>
  );
}
