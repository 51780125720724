import { NavLink } from 'react-router-dom';
import { useTrack } from '../../../shared/hooks/context/Track.Context';

export default function Menu() {
  const { track, loading, error } = useTrack();

  if (!track) {
    return <>No track data available.</>;
  }

  return (
    <>
      <hr />
      <NavLink to={`/tracks/view/${track.url}`}>Home</NavLink> - <NavLink to={`/tracks/view/${track.url}/news`}>News</NavLink> -{' '}
      <NavLink to={`/tracks/view/${track.url}/events`}>Events</NavLink>
      <hr />
    </>
  );
}
