import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import YouTubeVideoPlayer from './YuoTube.VideoPlayer';
import { PaginatedListDTO } from '../../../shared/interfaces/common/PaginatedList.DTO';
import { LessonDTO } from '../../../shared/interfaces/main/school/Lesson.DTO';
import api from '../../../shared/services/api/api';

interface CategoryLessonListProps {
  lessonProp: {
    categoryId: string;
    pageIndex: number;
    pageSize: number;
  };
}

export function CategoryLessonList({ lessonProp }: CategoryLessonListProps) {
  const [lessons, setLessons] = useState<PaginatedListDTO<LessonDTO> | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get(`/SchoolRequest/GetLessonsByCategoryId?categoryId=${lessonProp.categoryId}&pageIndex=1&pageSize=10`);
      setLessons(response.data);
    };
    fetchData();
  }, [lessonProp.categoryId]);

  if (!lessons) {
    return <>no data</>;
  }

  return (
    <>
      {lessons.items.map((lesson, i) => (
        <div key={i}>
          <Link to={`/school/view/${lesson.url}`}>{lesson.name}</Link>
          {lesson.isVideoPresentation ? (
            <div className="video-container">
              <div className="video-container">
                <YouTubeVideoPlayer videoLink={lesson.videoPresentationLink} width={'100%'} height={'420'} />
              </div>
            </div>
          ) : (
            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(lesson.presentation) }}></p>
          )}
        </div>
      ))}
      <div></div>
    </>
  );
}
