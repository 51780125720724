export enum ServiceNameEnum {
  // Дефолтное значение
  None = 0,

  // Основные сервисы (100-199)
  Track = 101,
  Club = 102,
  School = 103,

  // Вспомогательные сервисы (200-299)
  News = 201,
  Event = 202,
}
