import { Helmet } from 'react-helmet-async';

export default function MetaDescription({ name, description }: { name: string; description: string }) {
  const stripHtmlTags = (html: string) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    // Удаляем теги и лишние пробелы/переводы строк
    const text = tempDiv.textContent || tempDiv.innerText || '';
    return text
      .replace(/\s+/g, ' ') // заменяем любое количество пробелов/переводов строк на один пробел
      .replace(/^\s+|\s+$/g, ''); // убираем пробелы в начале и в конце
  };

  const truncateDescription = (description: string, maxLength: number) => {
    const strippedDescription = stripHtmlTags(description);
    if (strippedDescription.length <= maxLength) {
      return strippedDescription;
    }

    const truncated = strippedDescription.substring(0, maxLength);
    const lastSpaceIndex = truncated.lastIndexOf(' ');
    const finalDescription = lastSpaceIndex > 0 ? truncated.substring(0, lastSpaceIndex) : truncated;

    return finalDescription + '...';
  };

  const metaDescription = truncateDescription(description, 150);

  return (
    <Helmet>
      <title>{name}</title>
      <meta name="description" content={metaDescription} />
    </Helmet>
  );
}
