import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import axios from 'axios';
import { CategoryDTO } from '../../../shared/interfaces/main/school/Category.DTO';
import api from '../../../shared/services/api/api';

export default function CategoryList() {
  const [categories, setCategories] = useState<CategoryDTO[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/SchoolRequest/GetCategoriesFullList');
        setCategories(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  if (!categories) {
    return <>no data</>;
  }
  return (
    <>
      {' '}
      <div>
        {categories.map((category) => (
          <div key={category.id}>
            {category.parentId === '0' ? (
              <div>
                <Link to={`/school/category/${category.url}`}>{category.name}</Link>
                {categories.map((subCategory, index) => (
                  <div key={category.id + index}>
                    {subCategory.parentId === category.id ? (
                      <div>
                        <Link to={`/school/category/${subCategory.url}`}>= {subCategory.name}</Link>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ))}
              </div>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
    </>
  );
}
