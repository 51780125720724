import { Link, NavLink } from 'react-router-dom';

export default function MotorsportCategoriesNav() {
  return (
    <>
      <div className="">
        <div className="">
          <div className="py-1">
            <Link type="button" to={'/hub/motorsport'} className="btn btn-outline-dark btn-sm" style={{ backgroundColor: '#39403b', color: '#fff' }}>
              Motorsport
            </Link>
          </div>
          <div className="py-1">
            <Link type="button" to={'/hub/motorsport/formula-racing'} className="btn btn-outline-dark btn-sm" style={{ backgroundColor: '#39403b', color: '#fff' }}>
              Formula racing
            </Link>
          </div>
          <div className="py-1">
            <Link type="button" to={'/hub/motorsport/rally'} className="btn btn-outline-dark btn-sm" style={{ backgroundColor: '#39403b', color: '#fff' }}>
              Rally
            </Link>
          </div>
          <div className="py-1">
            <Link type="button" to={'/hub/motorsport/motorbikes'} className="btn btn-outline-dark btn-sm" style={{ backgroundColor: '#39403b', color: '#fff' }}>
              Motrobikes
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
