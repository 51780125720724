import { Helmet } from 'react-helmet-async';
import GetClubList from '../components/GetClubList';
import ContentLayoutContainer from '../../../shared/components/ui/containers/ContentLayout.Container';

export default function ClubsHomePage() {
  const title = 'Club list';
  const description = 'This is a list of clubs.';

  return (
    <>
      <ContentLayoutContainer>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <h1>{title}</h1>
        <p>{description}</p>
        <div className="row">
          <div className="col-12 col-md-9">
            <GetClubList />
          </div>
          <div className="col-12 col-md-3"></div>
        </div>
      </ContentLayoutContainer>
    </>
  );
}
