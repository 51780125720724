import { useEffect, useState } from 'react';
import TopBarMobile from '../../../../../shared/components/layout/header/top-bar/Top.bar.mobile';
import MiddleBarMobile from '../../../../../shared/components/layout/header/middle-bar/Middle.bar.mobile';
import Topbar from '../../../../../shared/components/layout/header/top-bar/Top.bar';
import Middlebar from '../../../../../shared/components/layout/header/middle-bar/Middle.bar';
import TestTopBar from '../../../../../shared/components/layout/header/top-bar/Test.Top.Bar';
import TestHubButtons from '../../../../../shared/components/layout/header/top-bar/Test.HubButtons';
import MotorsportCategoriesNav from '../../../../motorsport/components/navs/Motorsport.Categories.Nav';

export default function LegacyMotorsHeader() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Отслеживание изменения размера экрана
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Очистка события при размонтировании компонента
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header>
      {isMobile ? (
        <div className="">
          <div className="">
            {/* <TopBarMobile /> */}
            <TestTopBar />
            <MiddleBarMobile />
          </div>
        </div>
      ) : (
        <>
          <TestTopBar />
          <TestHubButtons />
          <div className="">
            <div className="container-fluid container-fluid-top-legacymotors border border-2 border-dark rounded-3">
              <div className="row top-row gradient-row">
                <div className=" position-relative">
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <h1 className="text-secondary">LEGACY MOTORS</h1>
                  </div>
                </div>
                <div className="">
                  <div className="navbar-underline">
                    <div className="pt-2">{/* <MotorsportCategoriesNav /> */}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </header>
  );
}
