import { HubTypeEnum } from '../../interfaces/enums/HubType.Enum';

export function getHubUrlPrefix(hub: HubTypeEnum): string {
  switch (hub) {
    case HubTypeEnum.Motorsport:
      return '/hub/motorsport';
    case HubTypeEnum.AutoWorld:
      return '/hub/auto-world';
    case HubTypeEnum.Motorbikes:
      return '/hub/motorbikes';
    case HubTypeEnum.LegacyMotors:
      return '/hub/legacy-motors';
    case HubTypeEnum.HeavyWheels:
      return '/hub/heavy-wheels';
    case HubTypeEnum.Roadtrip:
      return '/hub/roadtrip-overland';
    case HubTypeEnum.None:
    default:
      return ''; // Если нет хаба, то возвращаем пустую строку (без префикса)
  }
}
