import { Outlet, Route, Routes } from 'react-router-dom';
import MotorsportPage from '../pages/Motorsport.Page';
import NewsPage from '../pages/News.Page';
import MotorsportFeedSection from '../sections/feed/Motorsport.Feed.Section';
import TracksPage from '../pages/Tracks.Page';
import TracksSection from '../sections/track/Tracks.Section';
import TrackViewSection from '../sections/track/Track.View.Section';
import TrackContextProvider from '../../../shared/hooks/context/Track.Context';
import TrackAbout from '../../../shared/services/main/tracks/Track.About';
import NewsSection from '../sections/news/News.Section';
import NewsListSection from '../sections/news/News.List.Section';
import NewsViewSection from '../sections/news/News.View.Section';
import NewsListByTrackSection from '../sections/news/News.List.ByTrack.Section';
import FormulaRacingNonAuthRoutes from './formula-racing-routes/FormulaRacing.NonAuth.Routes';
import MotorsportLayout from '../components/layouts/motorsport-layouts/Motorsport.Layout';
import MotorsportHomeSection from '../sections/Motorsport.Home.Section';
import RallyNonAuthRoutes from './rally-routes/Rally.NonAuth.Routes';
import MotorbikesNonAuthRoutes from './motorbikes-routes/Motorbikes.NonAuth.Routes';
import EventsPage from '../pages/Events.Page';
import EventListSection from '../sections/event/Event.List.Section';
import EventViewSection from '../sections/event/Event.View.Section';
import EventListByTrackSection from '../sections/event/Event.List.ByTrack.Section';
import EventSection from '../sections/event/Event.Section';

export default function MotorsportNonAuthRoutes() {
  return (
    <>
      {/* prettier-ignore */}
      <Routes>
        <Route path="formula-racing/*" element={<FormulaRacingNonAuthRoutes />}></Route>
        <Route path="rally/*" element={<RallyNonAuthRoutes />}></Route>
        <Route path="motorbikes/*" element={<MotorbikesNonAuthRoutes />}></Route>


        <Route path="" element={<MotorsportLayout />}>
          <Route path="" element={<MotorsportPage />}>
          
          <Route path="" element={<MotorsportHomeSection />}></Route>

            <Route path="feed" element={<MotorsportFeedSection />}></Route>

            <Route path="news" element={<NewsPage />}>
              <Route path="" element={<NewsListSection />}></Route>
              <Route path="view/:newsUrl" element={<>News view</>}></Route>
              <Route path="view/" element={<>404</>}></Route>
            </Route>

            <Route path="tracks" element={<TracksPage />}>
              <Route path="" element={<TracksSection />}></Route>
              <Route
                path="view/:trackUrl"
                element={
                  <TrackContextProvider>
                    <TrackViewSection />
                  </TrackContextProvider>
                }
              >
                <Route path="" element={<TrackAbout />}></Route>
                <Route path="news" element={<NewsSection />}>
                  <Route path="" element={<NewsListByTrackSection />}></Route>
                  <Route path="view/:newsUrl" element={<NewsViewSection />}></Route>
                </Route>
                <Route path="events" element={<EventSection/>}>
                  <Route path="" element={<EventListByTrackSection />}></Route>
                  <Route path="view/:eventUrl" element={<EventViewSection/>}></Route>
                </Route>
                
              </Route>
            </Route>

            <Route path="clubs" element={<>Clubs</>}></Route>

            <Route path="events" element={<EventsPage/>}>
              <Route path="" element={<EventListSection />}></Route>
            </Route>

          </Route>
        </Route>
      </Routes>
    </>
  );
}
