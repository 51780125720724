import { useTrack } from '../../../../shared/hooks/context/Track.Context';
import { HubTypeEnum } from '../../../../shared/interfaces/enums/HubType.Enum';
import { ServiceNameEnum } from '../../../../shared/interfaces/enums/ServiceName.Enum';
import EventListByServiceAndObject from '../../../../shared/services/sub/events/EventList.ByServiceAndObject';
import NewsList from '../../../../shared/services/sub/news/NewsList';
import NewsListByServiceAndObject from '../../../../shared/services/sub/news/NewsList.ByServiceAndObject';
import MetaDescription from '../../../../shared/utils/seo/MetaDescription';

export default function NewsListByTrackSection() {
  const track = useTrack();
  return (
    <>
      <MetaDescription name={track.track?.name + ' - Events ' || ''} description={'This section contains all events regarding ' + track.track?.name || ''} />

      <EventListByServiceAndObject
        event={{
          serviceObjectUrl: track.track?.url || '',
          serviceName: ServiceNameEnum.Event,
          serviceObjectId: track.track?.id || '',
        }}
      />
    </>
  );
}
