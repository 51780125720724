import { Outlet } from 'react-router-dom';
import ContentLayoutContainer from '../../../../shared/components/ui/containers/ContentLayout.Container';
import HubNav from '../../components/navs/Hub.Nav';
import MotorsportCatNav from '../../components/navs/Motorsport.Cat.Nav';
import MotorsportCategoriesNav from '../../components/navs/Motorsport.Categories.Nav';
import MotorsportTestHeader from '../../components/layouts/motorsport-layouts/Motorsport.Test.Header';

export default function FormulaRacingCategory() {
  return (
    <>
      <div className="pt-1">
        <div className="container-fluid py-2">
          <HubNav />
        </div>
      </div>
      <div className="container-fluid pt-1 p-0">
        <Outlet />
      </div>
    </>
  );
}
