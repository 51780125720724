import { useTrack } from '../../../../shared/hooks/context/Track.Context';
import NewsView from '../../../../shared/services/sub/news/NewsView';
import Menu from '../../components/Menu';

export default function NewsViewSection() {
  const { track, loading, error } = useTrack();

  if (!track) {
    return <>Loading...</>;
  }

  return (
    <>
      <h1>{track.name}</h1>
      <Menu />

      <NewsView />
    </>
  );
}
