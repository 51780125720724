import { useState } from 'react';
import ContentLayoutContainer from './ui/containers/ContentLayout.Container';

export default function BlockTemp() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleBlock = () => setIsOpen(!isOpen);
  return (
    <>
      <ContentLayoutContainer>Motorsport test block</ContentLayoutContainer>
    </>
  );
}
