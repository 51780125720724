import { Outlet } from 'react-router-dom';
import MetaDescription from '../../../shared/utils/seo/MetaDescription';

export default function NewsPage() {
  return (
    <>
      <Outlet />
    </>
  );
}
