import { Outlet } from 'react-router-dom';

import FormulaRacingHeader from './header/Rally.Header';
import Footer from '../../../../../../shared/components/layout/Footer';

export default function FormulaRacingLayout() {
  return (
    <>
      <FormulaRacingHeader />

      <Outlet />

      <Footer />
    </>
  );
}
