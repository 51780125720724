import { Outlet, Route, Routes } from 'react-router-dom';
import F1Layout from '../../categories/formula-racing/f1/F1.Layout';
import F1SubCategory from '../../categories/formula-racing/f1/F1.SubCategory';
import F1NonAuthRoutes from './f1-routes/F1.NonAut.Routes';
import F2NonAuthRoutes from './f2-routes/F2.NonAut.Routes';
import MotorsportLayout from '../../components/layouts/motorsport-layouts/Motorsport.Layout';
import FormulaRacingLayout from '../../components/layouts/formula-racing-layouts/formula-racing-layout/FormulaRacing.Layout';
import FormulaRacingCategory from '../../categories/formula-racing/FormulaRacing.Category';

export default function FormulaRacingNonAuthRoutes() {
  return (
    <>
      {/* prettier-ignore */}
      <Routes>
        <Route path="" element={<Outlet />}>

            <Route path="" element={<FormulaRacingLayout />}>
              <Route path="" element={<FormulaRacingCategory/>}></Route>
            </Route>

            <Route path="f1" element={<F1NonAuthRoutes />}></Route>
            <Route path="f2" element={<F2NonAuthRoutes/>}></Route>
            <Route path="f3" element={<>F3</>}></Route>

          
        </Route>
      </Routes>
    </>
  );
}
