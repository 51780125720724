import { Outlet } from 'react-router-dom';
import Header from '../../../../../shared/components/layout/header/Header';
import Footer from '../../../../../shared/components/layout/Footer';
import MotorsportHeader from './Motorsport.Header';

export default function MotorsportLayout() {
  return (
    <>
      <MotorsportHeader />

      <Outlet />

      <Footer />
    </>
  );
}
