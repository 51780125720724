import { Route, Routes } from 'react-router-dom';
import Layout from '../../../shared/components/layout/Layout';
import LegacyMotorsHomeSection from '../sections/LegacyMotors.HomeSection';
import LegacyMotorsLayout from '../components/layouts/legacy-motors-layout/LegacyMotors.Layout';

export default function LegacyMotorsNonAuthRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LegacyMotorsLayout />}>
        <Route path="" element={<LegacyMotorsHomeSection />}></Route>
      </Route>
    </Routes>
  );
}
