import { Outlet } from 'react-router-dom';
import Header from '../../../../../shared/components/layout/header/Header';
import Footer from '../../../../../shared/components/layout/Footer';

export default function F1Layout() {
  return (
    <>
      <Header />

      <Outlet />

      <Footer />
    </>
  );
}
