export default function ContentLayoutContainer({ children }: { children: React.ReactNode }) {
  return (
    <>
      <div className="h-100">
        <div className="container content-box rounded-3 border border-2 border-dark" style={{ maxWidth: '1000px' }}>
          <div className="">{children}</div>
        </div>
      </div>
    </>
  );
}
