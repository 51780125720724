import { Route, Routes } from 'react-router-dom';
import Layout from '../../../shared/components/layout/Layout';

export default function HeavyWheelsNonAuthRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="" element={<>Heavy wheels hub route</>}></Route>
      </Route>
    </Routes>
  );
}
