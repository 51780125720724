import { useEffect, useState } from 'react';
import MiddleBarMobile from './middle-bar/Middle.bar.mobile';
import TestTopBar from './top-bar/Test.Top.Bar';
import TestHubButtons from './top-bar/Test.HubButtons';

export default function Header() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Отслеживание изменения размера экрана
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Очистка события при размонтировании компонента
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header>
      {isMobile ? (
        <div className="">
          <div className="">
            {/* <TopBarMobile /> */}
            <TestTopBar />
            <MiddleBarMobile />
          </div>
        </div>
      ) : (
        <>
          <TestTopBar />
          <TestHubButtons />
        </>
      )}

      {/* <div className="middle-line"> &nbsp;</div> */}
    </header>
  );
}
