import { NavLink } from 'react-router-dom';

export default function TestHubButtons() {
  const categories = [
    { name: 'Motorsport', link: '/hub/motorsport', img: '/img/motorsport.jpg' },
    { name: 'Auto World', link: '/hub/auto-world', img: '/img/autoworld.jpg' },
    { name: 'Motorbikes', link: '/hub/motorbikes', img: '/img/motorbikes.jpg' },
    { name: 'Legacy Motors', link: '/hub/legacy-motors', img: '/img/legacymotors.jpg' },
  ];

  return (
    <div className="container-lg-auto my-4">
      <div className="row justify-content-center g-3">
        {categories.map((category, index) => (
          <div className="col-lg-auto col-lg-auto-6 col-lg-auto-12" key={index}>
            <NavLink to={category.link} className={({ isActive }) => `category-card border border-2 ${isActive ? 'border-warning bg-light shadow' : 'border-dark'}`}>
              <img src={category.img} alt={category.name} className="category-image" />
              <span className="category-text">{category.name}</span>
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
}
