import { Link } from 'react-router-dom';

export default function TestTopBar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container" style={{ maxWidth: '1000px' }}>
          <Link className="navbar-brand" to="/">
            SPEED FORGE
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* <div className="collapse navbar-collapse justify-content-end" id="navbarTogglerDemo01">
            <ul className="navbar-nav">
              <li className="nav-item active">
                <a className="nav-link" href="">
                  My Space <span className="sr-only">(current)</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Account
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Dilon
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </nav>
    </>
  );
}
