import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CategoryList from '../components/CategoryList';
import { CategoryLessonList } from '../components/CategoryLesson.List';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { CategoryDTO } from '../../../shared/interfaces/main/school/Category.DTO';
import api from '../../../shared/services/api/api';
import ContentLayoutContainer from '../../../shared/components/ui/containers/ContentLayout.Container';

export default function CategoryPage() {
  const { categoryUrl, pageIndex, pageSize } = useParams();
  const [category, setCategory] = useState<CategoryDTO | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get(`SchoolRequest/GetCategoryByUrl?url=${categoryUrl}`);
      setCategory(response.data);
    };
    fetchData();
  }, [categoryUrl]);

  if (!category) {
    return <>no data</>;
  }

  const stripHtmlTags = (html: string) => {
    return html.replace(/<\/?[^>]+(>|$)/g, '');
  };

  // Генерация мета-описания: ограничение до 150 символов, обрезка слов и добавление многоточия, если текст был обрезан.
  const truncateDescription = (description: string, maxLength: number) => {
    const strippedDescription = stripHtmlTags(description);
    if (strippedDescription.length <= maxLength) {
      return strippedDescription;
    }

    // Обрезаем строку до максимально допустимой длины
    const truncated = strippedDescription.substring(0, maxLength);

    // Находим последнее пространство, чтобы избежать обрыва слова
    const lastSpaceIndex = truncated.lastIndexOf(' ');

    // Если пробел найден, обрезаем строку до этого места
    const finalDescription = lastSpaceIndex > 0 ? truncated.substring(0, lastSpaceIndex) : truncated;

    return finalDescription + '...';
  };

  const metaDescription = truncateDescription(category.description, 150);

  return (
    <ContentLayoutContainer>
      <Helmet>
        <title>{category.name}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <div className="row">
        <div className="col-9">
          <CategoryLessonList
            lessonProp={{
              categoryId: category.id,
              pageIndex: pageIndex ? parseInt(pageIndex, 10) : 0,
              pageSize: pageSize ? parseInt(pageSize, 10) : 10,
            }}
          />
        </div>
        <div className="col-3">
          <CategoryList />
        </div>
      </div>
    </ContentLayoutContainer>
  );
}
