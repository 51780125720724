import { Outlet } from 'react-router-dom';

export default function F2Layout() {
  return (
    <>
      <>
        <Outlet />
      </>
    </>
  );
}
