import React, { useEffect, useState } from 'react';
import api from '../../api/api';
import { PaginatedListDTO } from '../../../interfaces/common/PaginatedList.DTO';
import { IntegrationInterfaceDTO } from '../../../interfaces/IntegrationBridge/IntegrationInterface.DTO';
import { HubTypeEnum } from '../../../interfaces/enums/HubType.Enum';
import TrackSingleToList from './Track.Single.ToList';
import { ServiceNameEnum } from '../../../interfaces/enums/ServiceName.Enum';
import ContentLayoutContainer from '../../../components/ui/containers/ContentLayout.Container';
import BlockTemp from '../../../components/Block.Temp';

export default function TrackListByHubII({ hubType }: { hubType: HubTypeEnum }) {
  const [paginatedData, setPaginatedData] = useState<PaginatedListDTO<IntegrationInterfaceDTO> | null>(null);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get(`/IntBridgeRequest/GetIIListByHubTypeAndService?hubType=${hubType}&serviceName=${ServiceNameEnum.Track}&pageIndex=${pageIndex}`);

        if (response.data && response.data.items) {
          setPaginatedData(response.data);
        } else {
          throw new Error('Invalid API response');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [hubType, pageIndex]);

  // Обработка состояний загрузки и ошибки
  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  if (!paginatedData || paginatedData.items.length === 0) {
    return <>No data available</>;
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-9">
          <div>
            {paginatedData.items.map((intInterface) => (
              <div key={intInterface.id}>
                <TrackSingleToList trackId={intInterface.parentObjectId} />
              </div>
            ))}
          </div>
        </div>
        <div className="col-lg-3">
          <BlockTemp />
        </div>
      </div>

      {/* Pagination */}
      {/* <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', gap: '10px' }}>
        <button disabled={!paginatedData.hasPreviousPage} onClick={() => setPageIndex((prev) => prev - 1)}>
          ⬅️ Previous
        </button>

        <span>
          Page {paginatedData.pageIndex} of {paginatedData.totalPages}
        </span>

        <button disabled={!paginatedData.hasNextPage} onClick={() => setPageIndex((prev) => prev + 1)}>
          Next ➡️
        </button>
      </div> */}
    </>
  );
}
