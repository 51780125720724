import { useTrack } from '../../../../shared/hooks/context/Track.Context';
import { ServiceNameEnum } from '../../../../shared/interfaces/enums/ServiceName.Enum';
import NewsListByServiceAndObject from '../../../../shared/services/sub/news/NewsList.ByServiceAndObject';
import MetaDescription from '../../../../shared/utils/seo/MetaDescription';

export default function NewsListByTrackSection() {
  const track = useTrack();
  if (!track) {
    return <>Loading</>;
  }
  return (
    <>
      <MetaDescription name={track.track?.name + ' - News ' || ''} description={'This section contains all news regarding ' + track.track?.name || ''} />

      <NewsListByServiceAndObject
        news={{
          serviceObjectUrl: track.track?.url || '',
          serviceName: ServiceNameEnum.News,
          serviceObjectId: track.track?.id || '',
        }}
      />
    </>
  );
}
