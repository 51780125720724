import { Route, Routes } from 'react-router-dom';
import TracksHomePage from '../pages/TracksHome.Page';
import TrackPage from '../pages/Track.Page';
import AboutSection from '../sections/track-page-section/About.Section';
import NewsSection from '../sections/track-page-section/News.Section';
import EventsSection from '../sections/track-page-section/Events.Section';
import NewsViewSection from '../sections/track-page-section/NewsView.Section';
import TrackContextProvider from '../../../shared/hooks/context/Track.Context';
import Layout from '../../../shared/components/layout/Layout';

export default function TracksNonAuthRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<TracksHomePage />}></Route>
        <Route
          path="/view/:url"
          element={
            <TrackContextProvider>
              <TrackPage />
            </TrackContextProvider>
          }
        >
          <Route path="" element={<AboutSection />}></Route>
          <Route path="news" element={<NewsSection />}></Route>
          <Route path="news/view/:newsUrl" element={<NewsViewSection />}></Route>
          <Route path="events" element={<EventsSection />}></Route>
          <Route path="events/view/:newsUrl" element={<>New view page</>}></Route>
        </Route>
      </Route>
    </Routes>
  );
}
