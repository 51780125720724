import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import api from '../../api/api';
import { EventDTO } from '../../../interfaces/sub/events/Event.DTO';
import { useParams } from 'react-router-dom';
import MetaDescription from '../../../utils/seo/MetaDescription';

export default function EventView() {
  const [event, setEvent] = useState<EventDTO | null>(null);
  const { eventUrl } = useParams();
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get(`/EventRequest/GetEventByUrl?url=${eventUrl}`);
        if (response.data) {
          setEvent(response.data);
        } else {
          throw new Error('Invalid API response');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(`Failed to load data`);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  if (!event) {
    return <>No data available</>;
  }

  const formatDate = (date?: string) => (date ? new Date(date).toLocaleString('en-GB', { dateStyle: 'medium', timeStyle: 'short' }) : 'N/A');

  return (
    <>
      <MetaDescription name={event.name} description={event.description} />
      <img
        src={event.logo}
        alt="Card"
        style={{
          width: '100%',
          height: '180px',
          objectFit: 'cover',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      />
      <h2 className="pt-3">{event.name}</h2>
      <small>
        📅 <strong>Duration:</strong> {formatDate(event.eventStartDate)} → {formatDate(event.eventEndDate)}
      </small>
      <hr />
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(event.description) }}></div>
      <div>
        Link:{' '}
        <a href={event.link} target="_Blank">
          Event source
        </a>
      </div>
    </>
  );
}
