import { useEffect, useState } from 'react';
import api from '../../api/api';
import { getHubUrlPrefix } from '../../../utils/services/GetHubUrlPrefix';
import { NewsServiceExtensionDTO } from '../../../interfaces/sub/news/News.ServiceExtension.DTO';
import { getServiceUrlPrefix } from '../../../utils/services/GetServiceUrlPrefix';

export default function SingleNews({ id, serviceInfo }: { id: string; serviceInfo: boolean }) {
  const [news, setNews] = useState<NewsServiceExtensionDTO | null>(null);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get(`/NewsRequest/GetNewsAndServObjByNewsId?id=${id}`);
        if (response.data) {
          setNews(response.data);
        } else {
          throw new Error('Invalid API response');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(`Failed to load data`);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  if (!news) {
    return <>No data available</>;
  }
  const hubPrefix = getHubUrlPrefix(news.hubType);
  const servicePrefix = getServiceUrlPrefix(news.parentServiceName);
  const newsUrl = `${hubPrefix}${servicePrefix}/view/${news.parentUrl}/news/view/${news.url}`;
  return (
    <>
      <a href={newsUrl} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
        <div
          className="card"
          style={{
            flex: '1 1 calc(30% - 20px)',
            // minWidth: '250px',
            // maxWidth: '350px',
            border: '1px solid #fff',
            borderRadius: '8px',
            borderColor: 'black',
            boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            //flexDirection: 'column',
            //overflow: 'hidden',
            //transition: 'transform 0.3s ease', // Анимация увеличения
          }}
          // onMouseEnter={(e) => {
          //   (e.currentTarget as HTMLDivElement).style.transform = 'scale(1.05)';
          // }}
          // onMouseLeave={(e) => {
          //   (e.currentTarget as HTMLDivElement).style.transform = 'scale(1)';
          // }}
        >
          <div style={{ position: 'relative', width: '100%', height: '180px' }}>
            <img
              src={news.logo}
              alt="Card"
              style={{
                width: '100%',
                height: '180px',
                objectFit: 'cover',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
              }}
            />

            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '50px', // Увеличиваем высоту затемнения
                background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8), transparent)', // Усиливаем затемнение
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 20px', // Немного увеличиваем отступы для лучшей читабельности
                color: 'white',
                fontSize: '18px',
                fontWeight: 'bold',
              }}
            >
              <span>{news.parentName}</span>
              <span>News</span>
            </div>
          </div>

          <div className="card-body" style={{ flex: '1', padding: '10px' }}>
            <div className="card-text" style={{ color: '#333' }}>
              <h5>{news.name}</h5>
              <p>{news.shortContent}</p>
            </div>
          </div>
          <small className="card-text" style={{ marginTop: 'auto', padding: '10px', textAlign: 'right' }}>
            Published: {new Date(news.createdAt).toLocaleDateString('en-GB')}
          </small>
        </div>
      </a>
    </>
  );
}
