import ContentLayoutContainer from '../../../shared/components/ui/containers/ContentLayout.Container';
import MetaDescription from '../../../shared/utils/seo/MetaDescription';

export default function AutoWorldHomeSection() {
  return (
    <>
      <MetaDescription name="Auto World Hub" description="" />

      <div className="pt-2"></div>
      <div className="container-fluid">
        <ContentLayoutContainer>The hub is under construction</ContentLayoutContainer>
      </div>
    </>
  );
}
