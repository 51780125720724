import DOMPurify from 'dompurify';
import { useTrack } from '../../../hooks/context/Track.Context';
import MetaDescription from '../../../utils/seo/MetaDescription';

export default function TrackView() {
  const { track, loading, error } = useTrack();

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  if (!track) {
    return <>No track data available.</>;
  }

  return (
    <>
      <MetaDescription name={track.name} description={track.description} />
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(track.description) }}></div>
      <a href={track.link} target="_blank">
        Link to track website
      </a>
    </>
  );
}
