import { useEffect, useState } from 'react';

import { ServiceNameEnum } from '../../../interfaces/enums/ServiceName.Enum';
import { PaginatedListDTO } from '../../../interfaces/common/PaginatedList.DTO';
import { IntegrationInterfaceDTO } from '../../../interfaces/IntegrationBridge/IntegrationInterface.DTO';
import api from '../../api/api';
import SingleEvent from './SingleEvent';

interface eventList {
  serviceObjectUrl: string;
  serviceName: ServiceNameEnum;
  serviceObjectId: string;
}

export default function EventListByServiceAndObject({ event }: { event: eventList }) {
  const [intInterface, setIntInterface] = useState<PaginatedListDTO<IntegrationInterfaceDTO | null>>();
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get(
          `/IntBridgeRequest/GetIIListByServiceNameAndServiceObjectId?serviceName=${event.serviceName}&objectId=${event.serviceObjectId}&pageIndex=1&pageSize=100`,
        );

        if (response.data && response.data.items) {
          setIntInterface(response.data);
        } else {
          throw new Error('Invalid API response');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  if (!intInterface || intInterface.items.length === 0) {
    return <>No data available</>;
  }

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', // Автоматически 3 в ряд при достаточном размере
          gap: '15px', // Уменьшенное расстояние между карточками
          padding: '10px',
        }}
      >
        {intInterface.items.map((int) => (
          <SingleEvent key={int?.id} id={int?.id || ''} serviceInfo={false} />
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-evenly',
          padding: '20px',
          gap: '20px',
        }}
      >
        {/* <nav aria-label="...">
          <ul className="pagination">
            <li className="page-item disabled">
              <a className="page-link" href="#" aria-disabled="true">
                Previous
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                1
              </a>
            </li>
            <li className="page-item active" aria-current="page">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                Next
              </a>
            </li>
          </ul>
        </nav> */}
      </div>
    </>
  );
}
