import { NavLink } from 'react-router-dom';

export default function HubNav() {
  const buttons = [
    { name: 'Feed', link: '/hub/motorsport/feed' },
    { name: 'News', link: '/hub/motorsport/news' },
    { name: 'Tracks', link: '/hub/motorsport/tracks' },
    { name: 'Events', link: '/hub/motorsport/events' },
    // { name: 'Articles', link: '/hub/motorsport/articles' },
  ];

  return (
    <div className="px-0">
      <div className="d-flex flex-wrap justify-content-center nav-btn-container">
        {buttons.map((button, index) => (
          <NavLink key={index} to={button.link} className="full-width-btn" style={{ backgroundColor: '#6a7a6f' }}>
            {button.name}
          </NavLink>
        ))}
      </div>
    </div>
  );
}
