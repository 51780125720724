import { Outlet } from 'react-router-dom';
import { useTrack } from '../../../../shared/hooks/context/Track.Context';
import MetaDescription from '../../../../shared/utils/seo/MetaDescription';
import Menu from '../../../../services-main/tracks/components/Menu';
import TrackMenu from '../../../../shared/services/main/tracks/Track.Menu';
import ContentLayoutContainer from '../../../../shared/components/ui/containers/ContentLayout.Container';

export default function TrackViewSection() {
  const { track, loading, error } = useTrack();
  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  if (!track) {
    return <>No track data available.</>;
  }

  return (
    <>
      <ContentLayoutContainer>
        {/* <MetaDescription name={track.name} description={track.description} /> */}
        <h2 className="">{track.name}</h2>
        <TrackMenu url={track.url} hub={track.assignedHub} />
        <hr />
        <Outlet />
      </ContentLayoutContainer>
    </>
  );
}
