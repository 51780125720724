import { Link } from 'react-router-dom';
import ContentLayoutContainer from '../../shared/components/ui/containers/ContentLayout.Container';
import { Helmet } from 'react-helmet-async';
import Layout from '../../shared/components/layout/Layout';
import FeedAllHubs from '../components/Feed.AllHubs';

export default function HomePage() {
  const title = 'Welcome to Speed forge';
  const description =
    'Speed Forge: Enhance your driving skills with our dedicated school, discover thrilling race tracks, and join passionate car clubs. Learn, practice, and connect with fellow car enthusiasts.';

  return (
    <>
      <div className="container-fluid">
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>

        <h1 className="text-white">Welcome to Speed forge</h1>
        <hr className="text-white" />
      </div>
      <div className="container-fluid">
        <FeedAllHubs />
      </div>
    </>
  );
}
