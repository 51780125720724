import { Route, Routes } from 'react-router-dom';
import F2Layout from '../../../categories/formula-racing/f2/F2.Layout';

export default function F2NonAuthRoutes() {
  return (
    <>
      {/* prettier-ignore */}
      <Routes>
        <Route path="" element={<F2Layout />}>
          <Route path="" element={<>F2 sub category</>}></Route>
          <Route path="news" element={<>F2 News</>}></Route>
          <Route path="articles" element={<>F2 Articles</>}></Route>
          <Route path="events" element={<>F2 Events</>}></Route>
        </Route>
      </Routes>
    </>
  );
}
