import ContentLayoutContainer from '../../../../shared/components/ui/containers/ContentLayout.Container';
import { HubTypeEnum } from '../../../../shared/interfaces/enums/HubType.Enum';
import { ServiceNameEnum } from '../../../../shared/interfaces/enums/ServiceName.Enum';
import NewsList from '../../../../shared/services/sub/news/NewsList';
import NewsListByHub from '../../../../shared/services/sub/news/NewsList.ByHub';
import MetaDescription from '../../../../shared/utils/seo/MetaDescription';

export default function MotorsportFeedSection() {
  return (
    <>
      <MetaDescription name="Motorsport Hub - Feed" description="This section contains the entire feed of new content from all sections" />
      <div className="container-fluid">
        <ContentLayoutContainer>
          Welcome to motorsport feed section!
          <br /> Section is under construction..
        </ContentLayoutContainer>
      </div>
    </>
  );
}
