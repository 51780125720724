import { NavLink } from 'react-router-dom';
import { CSSProperties } from 'react';

export default function MiddleBarMobile() {
  const categories = [
    { name: 'Motorsport', link: '/hub/motorsport', img: '/img/motorsport.jpg' },
    { name: 'Auto World', link: '/hub/auto-world', img: '/img/autoworld.jpg' },
    { name: 'Motorbikes', link: '/hub/motorbikes', img: '/img/motorbikes.jpg' },
    { name: 'Legacy Motors', link: '/hub/legacy-motors', img: '/img/legacymotors.jpg' },
  ];

  const styles: { [key: string]: CSSProperties } = {
    card: {
      position: 'relative',
      display: 'block',
      width: '100%',
      height: '100px',
      overflow: 'hidden',
      borderRadius: '6px',
      textDecoration: 'none',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transition: 'transform 0.2s ease-in-out',
    },
    text: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      padding: '6px 0',
      background: 'rgba(33, 37, 41, 0.75)',
      color: 'white',
      textAlign: 'center',
      fontWeight: 500,
      fontSize: '1rem',
    },
  };

  return (
    <div className="container-lg-auto my-4 middlebar-mobile px-2">
      <div className="row justify-content-center gy-3">
        {categories.map((category, index) => (
          <div className="col-lg-auto col-12" key={index}>
            <NavLink
              to={category.link}
              style={styles.card}
              className={({ isActive }) => `border border-2 ${isActive ? 'border-info bg-light shadow-sm' : 'border-secondary'}`}
            >
              <img src={category.img} alt={category.name} style={styles.image} />
              <span style={styles.text}>{category.name}</span>
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
}
