import { Outlet, Route, Routes } from 'react-router-dom';
import F1Layout from '../../../categories/formula-racing/f1/F1.Layout';
import F1SubCategory from '../../../categories/formula-racing/f1/F1.SubCategory';

export default function F1NonAuthRoutes() {
  return (
    <>
      {/* prettier-ignore */}
      <Routes>
        <Route path="" element={<F1Layout />}>
          <Route path="" element={<F1SubCategory />}></Route>
          <Route path="news" element={<>F1 News</>}></Route>
          <Route path="articles" element={<>F1 Articles</>}></Route>
          <Route path="events" element={<>F1 Events</>}></Route>
        </Route>
      </Routes>
    </>
  );
}
