import BlockTemp from '../../../../shared/components/Block.Temp';
import ContentLayoutContainer from '../../../../shared/components/ui/containers/ContentLayout.Container';
import { useTrack } from '../../../../shared/hooks/context/Track.Context';
import { HubTypeEnum } from '../../../../shared/interfaces/enums/HubType.Enum';
import { ServiceNameEnum } from '../../../../shared/interfaces/enums/ServiceName.Enum';
import EventListByHubAndService from '../../../../shared/services/sub/events/EventList.ByHubAndService';
import MetaDescription from '../../../../shared/utils/seo/MetaDescription';
import MotorsportCatNav from '../../components/navs/Motorsport.Cat.Nav';

export default function EventListSection() {
  // const track = useTrack();

  // if (!track) {
  //   return <>Loading</>;
  // }

  return (
    <>
      {' '}
      <MetaDescription name="Motorsport Hub - Events" description="This section includes all categories related to motorsport events coverage." />
      <div className="container-fluid">
        <h2
          className=" pt-2"
          style={{
            color: 'rgba(224, 224, 224, 0.7)',
          }}
        >
          Events
        </h2>
        <hr className="text-white" />
        <div className="row">
          <div className="col-lg-9">
            <EventListByHubAndService event={{ hubName: HubTypeEnum.Motorsport, serviceName: ServiceNameEnum.Event }} />
          </div>
          <div className="col-lg-3">
            <BlockTemp />
          </div>
        </div>
      </div>
    </>
  );
}
