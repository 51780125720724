import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './shared/components/layout/Layout';
import HomePage from './home/pages/Home.Page';
import SchoolNonAuthRoutes from './services-main/school/routes/School.NonAuth.Routes';
import ClubsNonAuthRoutes from './services-main/clubs/routes/Clubs.NonAuth.Routes';
import TracksNonAuthRoutes from './services-main/tracks/routes/Tracks.NonAuth.Routes';
import MotorsportNonAuthRoutes from './hubs/motorsport/routes/Motrosport.NonAuth.Routes';
import AutoWorldNonAuthRoutes from './hubs/auto-world/routes/AutoWorld.NonAuth.Routes';
import MotorbikesNonAuthRoutes from './hubs/motorbikes/routes/Motorbikes.NonAuth.Routes';
import LegacyMotorsNonAuthRoutes from './hubs/legacy-motors/routes/LegacyMotors.NonAuth.Routes';
import HeavyWheelsNonAuthRoutes from './hubs/heavy-wheels/routes/HeavyWheels.NonAuth.Routes';
import RoadtripNonAuthRoutes from './hubs/road-trip/routes/Roadtrip.NonAuth.Routes';

function App() {
  return (
    <>
      {/* prettier-ignore */}
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<HomePage />} />
            </Route>           
            <Route path="account/*" element={<>Some path</>} />
            <Route path="identity/*" element={<>Some path</>} />
            <Route path="school/*" element={<SchoolNonAuthRoutes />} />
            <Route path="clubs/*" element={<ClubsNonAuthRoutes />} />
            <Route path="tracks/*" element={<TracksNonAuthRoutes/>} />

            <Route path="hub/motorsport/*" element={<MotorsportNonAuthRoutes/>} />
            <Route path="hub/auto-world/*" element={<AutoWorldNonAuthRoutes/>} />
            <Route path="hub/motorbikes/*" element={<MotorbikesNonAuthRoutes/>} />
            <Route path="hub/legacy-motors/*" element={<LegacyMotorsNonAuthRoutes/>} />
            <Route path="hub/heavy-wheels/*" element={<HeavyWheelsNonAuthRoutes/>} />
            <Route path="hub/roadtrip-overland/*" element={<RoadtripNonAuthRoutes/>} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
