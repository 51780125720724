import { HubTypeEnum } from '../../../../shared/interfaces/enums/HubType.Enum';
import TrackListByHub from '../../../../shared/services/main/tracks/TrackList.ByHub';
import TrackListByHubII from '../../../../shared/services/main/tracks/TrackList.ByHub.II';
import MetaDescription from '../../../../shared/utils/seo/MetaDescription';

export default function TracksSection() {
  return (
    <>
      <MetaDescription name="Motorsport Hub - Tracks" description="List of motorsport tracks, including all types of competitions" />
      <div className="container-fluid">
        <h2
          className=" pt-2"
          style={{
            color: 'rgba(224, 224, 224, 0.7)',
          }}
        >
          Tracks
        </h2>
        <hr className="text-white" />
        <TrackListByHubII hubType={HubTypeEnum.Motorsport} />
      </div>
    </>
  );
}
