import { Outlet } from 'react-router-dom';
import Header from '../../../../../shared/components/layout/header/Header';
import Footer from '../../../../../shared/components/layout/Footer';
import MotorsportHeader from './AutoWorld.Header';
import AutoWorldHeader from './AutoWorld.Header';

export default function AutoWorldLayout() {
  return (
    <>
      <AutoWorldHeader />

      <Outlet />

      <Footer />
    </>
  );
}
