import { Outlet } from 'react-router-dom';
import Footer from '../../../../../shared/components/layout/Footer';
import MotorbikesHeader from './Motorbikes.Header';

export default function MotorsportLayout() {
  return (
    <>
      <MotorbikesHeader />

      <Outlet />

      <Footer />
    </>
  );
}
