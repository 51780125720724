import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../services/api/api';
import { TrackDTO } from '../../interfaces/main/tracks/Track.DTO';

interface TrackContextProps {
  track: TrackDTO | null;
  loading: boolean;
  error: string | null;
}

const TrackContext = createContext<TrackContextProps | undefined>(undefined);

export const useTrack = () => {
  const context = useContext(TrackContext);
  if (!context) {
    throw new Error('useTrack must be used within a TrackProvider');
  }
  return context;
};

export default function TrackContextProvider({ children }: { children: React.ReactNode }) {
  const [track, setTrack] = useState<TrackDTO | null>(null);
  const { trackUrl } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get(`/TracksRequest/GetTrackByUrl?url=${trackUrl}`);
        setTrack(response.data);
      } catch (err) {
        setError('Failed to load track data');
      } finally {
        setLoading(false);
      }
    };

    if (trackUrl) {
      fetchData();
    }
  }, [trackUrl]);

  return <TrackContext.Provider value={{ track, loading, error }}>{children}</TrackContext.Provider>;
}
