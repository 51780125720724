import { Route, Routes } from 'react-router-dom';
import Layout from '../../../shared/components/layout/Layout';
import AutoWorldHomeSection from '../sections/AutoWorld.Home.Section';
import AutoWorldLayout from '../components/layouts/auto-world-layout/AutoWorld.Layout';

export default function AutoWorldNonAuthRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AutoWorldLayout />}>
        <Route path="" element={<AutoWorldHomeSection />}></Route>
      </Route>
    </Routes>
  );
}
