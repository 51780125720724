import { useEffect, useState } from 'react';
import { NewsDTO } from '../../../interfaces/sub/news/News.DTO';
import { useParams } from 'react-router-dom';
import api from '../../api/api';
import DOMPurify from 'dompurify';
import MetaDescription from '../../../utils/seo/MetaDescription';

export default function NewsVeiw() {
  const [news, setNews] = useState<NewsDTO | null>(null);
  const { newsUrl } = useParams();
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get(`/NewsRequest/GetNewsByUrl?url=${newsUrl}`);
        if (response.data) {
          setNews(response.data);
        } else {
          throw new Error('Invalid API response');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(`Failed to load data`);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  if (!news) {
    return <>No data available</>;
  }

  return (
    <>
      <MetaDescription name={news.name} description={news.fullContent} />
      <img
        src={news.logo}
        alt="Card"
        style={{
          width: '100%',
          height: '180px',
          objectFit: 'cover',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      />
      <h2 className="pt-3">{news.name}</h2>
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(news.fullContent) }}></div>
      <div>
        Link:{' '}
        <a href={news.link} target="_Blank">
          News source
        </a>
      </div>
    </>
  );
}
