import { Outlet } from 'react-router-dom';
import Header from '../../../../../shared/components/layout/header/Header';
import Footer from '../../../../../shared/components/layout/Footer';
import MotorsportHeader from './LegacyMotors.Header';
import LegacyMotorsHeader from './LegacyMotors.Header';

export default function LegacyMotorsLayout() {
  return (
    <>
      <LegacyMotorsHeader />

      <Outlet />

      <Footer />
    </>
  );
}
