import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import YouTubeVideoPlayer from '../components/YuoTube.VideoPlayer';
import { Helmet } from 'react-helmet-async';
import { LessonDTO } from '../../../shared/interfaces/main/school/Lesson.DTO';
import api from '../../../shared/services/api/api';
import ContentLayoutContainer from '../../../shared/components/ui/containers/ContentLayout.Container';

export default function LessonPage() {
  const { lessonUrl } = useParams();
  const [lesson, setLesson] = useState<LessonDTO | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get(`SchoolRequest/GetLessonByUrl?lessonUrl=${lessonUrl}`);
      setLesson(response.data);
    };
    fetchData();
  }, []);

  if (!lesson) {
    return <>no data</>;
  }

  const stripHtmlTags = (html: string) => {
    return html.replace(/<\/?[^>]+(>|$)/g, '');
  };

  // Генерация мета-описания: ограничение до 150 символов, обрезка слов и добавление многоточия, если текст был обрезан.
  const truncateDescription = (description: string, maxLength: number) => {
    const strippedDescription = stripHtmlTags(description);
    if (strippedDescription.length <= maxLength) {
      return strippedDescription;
    }

    // Обрезаем строку до максимально допустимой длины
    const truncated = strippedDescription.substring(0, maxLength);

    // Находим последнее пространство, чтобы избежать обрыва слова
    const lastSpaceIndex = truncated.lastIndexOf(' ');

    // Если пробел найден, обрезаем строку до этого места
    const finalDescription = lastSpaceIndex > 0 ? truncated.substring(0, lastSpaceIndex) : truncated;

    return finalDescription + '...';
  };

  const metaDescription = truncateDescription(lesson.description, 150);

  return (
    <ContentLayoutContainer>
      <Helmet>
        <title>{lesson.name}</title>
        <link rel="canonical" href={`https://speedforge.uk/school/view/${lesson.url}`} />
        <meta name="description" content={metaDescription} />
      </Helmet>

      <h1>{lesson.name}</h1>
      {lesson.isVideoPresentation ? (
        <div className="video-container">
          <YouTubeVideoPlayer videoLink={lesson.videoPresentationLink} width={'100%'} height={'650'} />
        </div>
      ) : (
        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(lesson.presentation) }}></p>
      )}
      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(lesson.description) }}></p>
    </ContentLayoutContainer>
  );
}
