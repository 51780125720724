import { Helmet } from 'react-helmet-async';
import DOMPurify from 'dompurify';

import Menu from '../../components/Menu';
import { useTrack } from '../../../../shared/hooks/context/Track.Context';
import MetaDescription from '../../../../shared/utils/seo/MetaDescription';

export default function AboutSection() {
  const { track, loading, error } = useTrack();

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  if (!track) {
    return <>No track data available.</>;
  }

  return (
    <>
      <MetaDescription name={track.name} description={track.description} />
      <h1>{track.name}</h1>
      <Menu />
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(track.description) }}></div>
      <a href={track.link} target="_blank">
        Link to track website
      </a>
    </>
  );
}
