import { Outlet, Route, Routes } from 'react-router-dom';
import FormulaRacingLayout from '../../components/layouts/formula-racing-layouts/formula-racing-layout/FormulaRacing.Layout';
import FormulaRacingCategory from '../../categories/formula-racing/FormulaRacing.Category';
import MotorbikesLayout from '../../components/layouts/motorbikes-layouts/motorbike-layout/Motorbikes.Layout';

export default function MotorbikesNonAuthRoutes() {
  return (
    <>
      {/* prettier-ignore */}
      <Routes>
        <Route path="" element={<Outlet />}>

            <Route path="" element={<MotorbikesLayout />}>
              <Route path="" element={<FormulaRacingCategory/>}></Route>
            </Route>



          
        </Route>
      </Routes>
    </>
  );
}
