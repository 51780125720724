import { ServiceNameEnum } from '../../interfaces/enums/ServiceName.Enum';

export function getServiceUrlPrefix(service: ServiceNameEnum): string {
  switch (service) {
    case ServiceNameEnum.Track:
      return '/tracks';
    case ServiceNameEnum.Club:
      return '/clubs';
    case ServiceNameEnum.School:
      return '/school';
    case ServiceNameEnum.None:
    default:
      return '';
  }
}
